import React from 'react'
import { withRouter } from 'react-router-dom'
import ButtonNavbar from '../../../components/molecules/buttonNavbar/ButtonNavbar'
import { PROFILE } from '../../../router/routes'
import ProfileTemplate from '../../../components/templates/profileTemplate/ProfileTemplate'
import UserMenuDesktop from '../../../components/molecules/menu/UserMenu'
import { camelCase } from '../../../res/utils'

const Profile = ({
    thumbnail,
    name,
    userType,
    callbackSelectImage,
    callbackSelectImageError,
    appbarButtonsContent,
    menuButtonsContent
}) => {

    return (
        <div className='sp-profile-container '>
            <UserMenuDesktop
                page={PROFILE}
                showBtn={true}
                popupContent={['Servicios', 'Garantias']} 
            />
            <ProfileTemplate
                appbarButtonsContent={appbarButtonsContent}
                menuButtonsContent={menuButtonsContent}
                thumbnail={thumbnail}
                name={camelCase(name)}
                userType={userType}
                callbackSelectImage={callbackSelectImage}
                callbackSelectImageError={callbackSelectImageError}
                navbar={
                    <div className='menu-navbar-container'>
                        <ButtonNavbar
                            page={PROFILE}
                            showBtn={true}
                            popupContent={['Servicios', 'Garantias']} />
                    </div>
                }

            />
        </div>
    )
}


export default withRouter(Profile)