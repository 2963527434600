import { Button, ConfigProvider, Modal } from "antd";
import { ReactComponent as BellLightIcon } from "assets/icons/svg/bell-light.svg";
import { ReactComponent as TimelineIcon } from "assets/icons/svg/map-icon.svg";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ALL_SERVICES_ADDED,
  CHECK_QR_CODE,
  NOTIFICATIONS,
  PROFILE,
  PS_BASE_MENU_PATH,
  ROUTE_TIMELINE,
  SERVICES
} from "router/routes";
import "./NavBar.css";

import AddNewOption from "./components/AddNewOptions";
import { CommentOutlined, PlusOutlined, ScanOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import OrderRequestService from "views/OrderRequestService/OrderRequestService";
import OrderAdditionalService from "views/OrderRequestService/OrderAdditionalService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function NavBarForService() {
  const { id } = useParams();
  const history = useHistory();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);

  const [showRequestForm, setShowRequestForm] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [isDialogToConfirm, setIsDialogToConfirm] = useState(false);
  const [confirmMaterialModal, setConfirmMaterialModal] = useState(false);
  const [showAdditionalService, setShowAdditionalService] = useState(false);

  function onHandleNewRequest(type) {

    if (type === "additionals") {
      setShowAdditionalService(true)

    } else {
      setShowRequestForm(true)
    }
    setRequestType(type)
  }

  function onCloseRequestForm() {
    setShowRequestForm(false)
  }

  return (
    <>
      <nav className="nav-bar">
        <ConfigProvider
          theme={{
          }}
        >
          <Button
            size="large"
            onClick={() => {
              history.push(CHECK_QR_CODE);
            }}
            data-active={history.location.pathname.includes(CHECK_QR_CODE)}
            className="nav-bar-opt"
          >
            <ScanOutlined />
          </Button>
          <Button
            size="large"
            onClick={() => {
              history.push(`/chat-phases/${id}`);
            }}
            data-active={history.location.pathname.includes(`/chat-phases/${id}`)}
            className="nav-bar-opt"
          >
            <CommentOutlined />

          </Button>

          <Button
            size="large"
            shape="circle"
            onClick={() => { setIsAddNewModalOpen(true) }}
            className="nav-bar-opt-center-ser"
          >
            <PlusOutlined />
          </Button>


          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + NOTIFICATIONS);
            }}
            data-active={history.location.pathname.includes(NOTIFICATIONS)}
            className="nav-bar-opt"
          >
            <BellLightIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + PROFILE);
            }}
            data-active={history.location.pathname.includes(PROFILE)}
            className="nav-bar-opt"
          >
            <SendOutlined />
          </Button>
        </ConfigProvider>
      </nav>

      <Modal
        centered
        closable
        maskClosable
        footer={null}
        title={null}
        open={isAddNewModalOpen && !showRequestForm && !isDialogToConfirm && !showAdditionalService}
        onCancel={() => {
          setIsAddNewModalOpen(false);
        }}
        rootClassName="options-modal"
      >
        <AddNewOption onHandleNewRequest={onHandleNewRequest} />
      </Modal>
      <OrderRequestService
        open={showRequestForm}
        closeRequest={onCloseRequestForm}
        requestType={requestType}
        openDialogToConfirm={() => setIsDialogToConfirm(true)}
        setCloseModal={() => setIsAddNewModalOpen(false)}
        closeConfirm={() => setIsDialogToConfirm(false)}
        setOpenDateForm={() => setShowRequestForm(true)}
        isDialogToConfirm={isDialogToConfirm}
      />

      {showAdditionalService &&
        <OrderAdditionalService
          requestType={requestType}
          openDialogToConfirm={() => setConfirmMaterialModal(true)}
          isDialogToConfirm={confirmMaterialModal}
          closeConfirm={() => setConfirmMaterialModal(false)}
          open={showAdditionalService}
          setOpenForm={() => setShowAdditionalService(true)}
          setCloseForm={() => setShowAdditionalService(false)}
          setCloseModal={() => setIsAddNewModalOpen(false)}

        />
      }

    </>
  );
}
