import { configHeaders } from "../services/handler.request";

const dbName = 'requestQueueDB';
const storeName = 'requestQueueStore';

function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = () => {
      const db = request.result;
      db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
    };
  });
}

function saveToIndexedDB(requestData) {
  openDB().then(db => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    store.add({ requestData, timestamp: Date.now() });
    transaction.oncomplete = () => db.close();
  }).catch(console.error);
}

function deleteFromIndexedDB(id) {
  openDB().then(db => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    store.delete(id);
    transaction.oncomplete = () => db.close();
  }).catch(console.error);
}

export function getPendingRequests() {
  return new Promise((resolve, reject) => {
    openDB().then(db => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result);
        db.close();
      };
      request.onerror = () => reject(request.error);
    }).catch(reject);
  });
}

export async function sendOfflineRequest(url, method, data, id = 0) {
  const connection = await checkConnectionQuality();
  if (connection === 'good') {
    const config = await configHeaders()
    try {
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json', ...config.headers },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        console.log(`${method} enviado con éxito:`, data);
        return true;
      } else {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error al enviar el ${method}, guardando para reintentar:`, error);
      if (!id) { 
        saveToIndexedDB({ url, method, data }); 
      }
      return false;
    }
  } else {
    console.warn('Conexión débil, guardando para reintentar más tarde');
    saveToIndexedDB({ url, method, data });
    return false;
  }
}

export async function resendPendingRequests() {
  try {
    const requests = await getPendingRequests();
    for (const request of requests) {
      const { url, method, data } = request.requestData;
      const success = await sendOfflineRequest(url, method, data, request.id);
      if (success) {
        deleteFromIndexedDB(request.id);
      }
    }
  } catch (error) {
    console.error('Error al reenviar solicitudes pendientes:', error);
  }
}
// good bad moderate offline
export async function checkConnectionQuality() {

  try {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    const url = "https://www.google.com/favicon.ico"; // URL de prueba para medir la velocidad

    // Categorizar usando la API Network Information, si está disponible
    // if (connection) {
    //   if (connection.saveData) return "bad"; // modo ahorro de datos activado
    //   if (["slow-2g", "2g"].includes(connection.effectiveType)) return "bad";
    //   if (["3g"].includes(connection.effectiveType)) return "moderate";
    //   if (["4g"].includes(connection.effectiveType)) return "good";
    // }

    // Si no está disponible, mide la latencia de una solicitud pequeña
    const startTime = Date.now();
    await fetch(url, { method: "HEAD", mode: "no-cors" });
    const latency = Date.now() - startTime;

    // Categorizar según la latencia medida
    if (latency > 1000) return "bad";
    if (latency > 500) return "moderate";
    return "good";
  } catch (error) {
    return "offline"; // Error de conexión
  }
}
// window.addEventListener('online', resendPendingRequests);
