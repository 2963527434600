import { Drawer, Tabs } from 'antd';
import useReduxState from 'custom-hooks/useReduxState';
import { useMemo, useState } from 'react';
import { setMediaSectionActive } from 'reducers/bitacle.reducer';
import './MediaSection.css';
import MediaAll from './components/mediaAll/MediaAll';
import MediaFiles from './components/mediaFiles/MediaFiles';
import MediaLinks from './components/mediaLinks/MediaLinks';
import MediaOthers from './components/mediaOthers/MediaOthers';
import MediaRenders from './components/mediaRenders/MediaRenders';

export default function MediaSection() {
  const [{ isMediaSectionActive }, dispatch] = useReduxState({ setMediaSectionActive, }, "bitacle")
  const [selectedTab, setSelectedTab] = useState("1");
  const tabOptions = useMemo(() => {
    return [
      {
        key: '1',
        label: 'Todos',
        children: <MediaAll />,
      },
      {
        key: '2',
        label: 'Archivos',
        children: <MediaFiles />,
      },
      {
        key: '3',
        label: 'Renders',
        children: <MediaRenders />,
      },
      {
        key: '4',
        label: 'Enlaces',
        children: <MediaLinks />,
      },
      {
        key: "5",
        label: "Otros",
        children: <MediaOthers />,
      },
    ]
  }, [selectedTab]) //eslint-disable-line

  return (
    <Drawer
      open={isMediaSectionActive}
      placement="right"
      closable={true}
      onClose={() => {
        dispatch.setMediaSectionActive(false)
      }}
      title="Lista de medios"
      size='large'
      maskClosable={false}
      destroyOnClose
      classNames={{
        body: "media-section-body",
      }}
    >
      <Tabs
        items={tabOptions}
        className="media-section-tabs"
        indicator={{
          size: (origin) => origin - 20,
          align: "center",
        }}
        onChange={(key) => {
          setSelectedTab(key)
        }}
      />
    </Drawer>
  )
}
