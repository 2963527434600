import { Avatar } from 'antd'
import GoBackBtn from 'components/GoBackBtn/GoBackBtn'
import "./Header.css"

export default function Header({ metadata }) {

  const avatar = {
    woman:
      "https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png",
    man: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/b3053232163929.567197ac6e6f5.png",
  };

  return (
    <header className='bitacle-phase-header'>
      <GoBackBtn />

      <section className='service-info-wrapper'>
        <Avatar
          src={metadata?.user?.gender ? avatar[metadata?.user.gender] : avatar[1]}
          alt="Avatar del cliente"
          size={48}
        />

        <div className='service-info'>
          {/* <h1 className='info-name'>{metadata?.property?.nickname}</h1> */}
          <h2 className='info-address'>
            {metadata?.property?.nickname}
          </h2>
        </div>
      </section>
    </header>
  )
}
