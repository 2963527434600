import { Button, Form, Input } from 'antd';
import { ReactComponent as MicrophoneIcon } from "assets/icons/svg/chat-microphone.svg";
import { ReactComponent as SendIcon } from "assets/icons/svg/chat-send.svg";
import useReduxState from 'custom-hooks/useReduxState';
import { useCallback, useEffect, useRef } from 'react';
import { setMessageChat } from 'reducers/bitacle.reducer';
import './ChatFooter.css';
import { useSelector } from 'react-redux';
import { createNewMessage } from 'services-controller/http.cleinte.controller';
import { getEmisorFromUser } from 'res/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function ChatFooter() {
  const [form] = Form.useForm()
  const messageWatch = Form.useWatch("message", form)
  const [{ chat, chatPhaseSelected }, dispatch] = useReduxState({ setMessageChat }, "bitacle",)
  const textAreaRef = useRef(null)
  const { id } = useParams()

  const user = useSelector(state => state.auth.user)

  const onSubmit = useCallback(async () => {
    const { message } = form.getFieldsValue()

    console.log(chat.length)

    // dispatch.setMessageChat(message)

    const emisor = getEmisorFromUser(user)

    console.log(user, "ee")
    let obj = {
      timestamp: new Date().toISOString(),
      is_valid: false,
      position: chat.length,
      emisor: emisor,
      user: emisor.name,
      message: message,
      admin_read: true,
      admin_read_date: new Date().toISOString(),
      client_read: false,
      client_read_date: "",
      sp_read: false,
      sp_read_date: "",
      type: "message",
      images: [],
      avatar: emisor.thumbnail,
    };

    await createNewMessage(id, chatPhaseSelected.key, user?.register_type === 200 ? 'sp' : "client", obj, emisor)

    form.resetFields()

  }, [form.getFieldsValue()]) // eslint-disable-line

  useEffect(() => {
    textAreaRef.current?.focus()
  }, [])

  return (
    <footer className='bitacle-chat-footer'>
      <Form
        id='chat-form'
        form={form}
        className='chat-form'
        onFinish={() => {
          onSubmit()
        }}
      >
        <Form.Item
          name="message"
        >
          <Input.TextArea
            className='message-input'
            placeholder="Escribe tu mensaje"
            ref={textAreaRef}
          />
        </Form.Item>
      </Form>

      <Button
        type="primary"
        form="chat-form"
        htmlType={messageWatch?.length ? "submit" : "button"}
        size="large"
        className="send-btn"
        icon={messageWatch?.trim()?.length ? <SendIcon /> : <MicrophoneIcon />}
      >
      </Button>
    </footer>
  )
}
