import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from '../../router/Router.jsx'

const MainApp = () => (
    <BrowserRouter  >
        <Router />
    </BrowserRouter>
)

export default MainApp