import { Button } from "antd"
import ProgressBar from "components/atoms/progess-bar/ProgressBar"
import useReduxState, { useDispatcherFunction } from "custom-hooks/useReduxState"
import { useHistory, useParams } from "react-router-dom/"
import { setChatPhaseSelected, setPhases } from "reducers/bitacle.reducer"
import { getPercentPhases } from "views/services-provider/ps-new-entry/phases"
import "./BitaclePhase.css"
import Header from "./header/Header"
import { useState } from "react"
import { useEffect } from "react"
import { getAllEntriesByService, getServiceById } from "services-controller/http.cleinte.controller"
import { useSelector } from "react-redux"


export default function BitaclePhase() {
  const [, dispatch] = useReduxState({ setChatPhaseSelected, }, "bitacle")
  const updatePhases = useDispatcherFunction(setPhases)

  const phasesOptions = useSelector(state => state.bitacle.phases)

  console.log(phasesOptions, "phases")

  const history = useHistory()

  const { id } = useParams();
  console.log(id, "id")
  const [service, setService] = useState(null);

  useEffect(() => {
    const fetchService = async () => {
      const res = await getServiceById(id);
      setService(res);
    }

    fetchService();

  }, [id])

  console.log(service)

  useEffect(() => {
    const getPhases = async () => {
      const response = await getAllEntriesByService(id)
      console.log(response.bitacle[0].entrys)
      updatePhases(response.bitacle[0].entrys)
    }

    getPhases()
  }, [id])


  const [phases, totalPercent] = getPercentPhases(service?.state?.globalState);

  return (
    <main className="bitacle-phase-view">
      <Header metadata={service?.metadata} />
      <ProgressBar {...{ phases }} total={totalPercent} />

      <section className="phases-section">
        <h1 className="section-title">Selecciona la fase</h1>

        <div className="phases-options">
          {phasesOptions.map((phase, index) => {
            const { id: key, name } = phase

            return (
              <Button
                key={index}
                className="phase-option"
                data-phase={key}
                onClick={() => {
                  dispatch.setChatPhaseSelected({ key, name })
                  const route = `/chat/${id}/fase/${key}`
                  console.log(route, "r")
                  history.push(route)
                }}
              >
                {name}
              </Button>
            )
          })}
        </div>
      </section>
    </main>
  )
}
