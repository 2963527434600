import { Avatar } from 'antd'
import { useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isALink, timeAgo } from 'res/utils'
import './ChatBody.css'

export default function ChatBody() {
  const chat = useSelector((state) => state.bitacle.chat, shallowEqual)
  const chatBodyRef = useRef(null)
  const chatPhaseSelected = useSelector((state) => state.bitacle.chatPhaseSelected, shallowEqual)

  useEffect(() => {
    chatBodyRef.current?.scrollTo(0, chatBodyRef.current?.scrollHeight)

  }, [chat, chatPhaseSelected,])

  const avatarOp = {
    woman:
      "https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png",
    man: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/b3053232163929.567197ac6e6f5.png",
  };

  return (
    <section className='bitacle-chat-body' ref={chatBodyRef}>
      {chat.map((item, index, array) => {
        const { message, avatar, user, timestamp, isOtherUser, type, comment, detail, title } = item
        const nextSame = array?.at(index + 1)?.user === user
        const prevSame = array?.at(index - 1)?.user === user
        const auxType = type ?? "message"

        return (
          <article
            key={index}
            className={
              [
                "chat-message",
                isOtherUser && "is-other-user",
                (prevSame) && "prev-same-user",
                (nextSame) && "next-same-user",
              ]
                .filter(Boolean)
                .join(" ")
            }
            data-type={auxType}
          >
            <section className="message-wrapper">
              {title && (<h1 className="header-title">{title}</h1>)}

              {
                isALink((message || comment || detail))
                  ? (
                    <a
                      href={message || comment || detail}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {message || comment || detail}
                    </a>
                  )

                  : (
                    <p className="message-paragraph">
                      {message || comment || detail}
                    </p>
                  )
              }
            </section>

            {!nextSame && (
              <footer className="chat-message-footer">
                {isOtherUser && (
                  <div className="footer-details">
                    <Avatar
                      size={26}
                      src={avatar ?? avatarOp[1]}
                      alt={`Avatar de ${user}`}
                      className='emisor-avatar'
                    />

                    <span className='emisor'>{user}</span>
                  </div>
                )}

                <time dateTime={timestamp} className="footer-date">
                  {timeAgo(timestamp)}
                </time>
              </footer>
            )}
          </article>
        )
      })}
    </section>
  )
}
