import React from "react";
import OptionsHeader from "./OptionsHeader";
import EntryTitle from "./EntryTitle";
import ExtraInfo from "./ExtraInfo";

const HeaderInfoContainer = ({
  activities,
  entryIsVisble,
  entryId,
  entryTitle,
  entryDate,
  callbackHiddenEntry,
}) => {
  return (
    <div className="header-info-container">
      <div className="cei-header-container">
        <div className="cei-header-right">
          <EntryTitle activities={activities} title={entryTitle} />

          <ExtraInfo date={entryDate} />
        </div>
        <OptionsHeader
          callbackHiddenEntry={callbackHiddenEntry}
          objectId={entryId}
          entryIsVisble={entryIsVisble}
        />
      </div>
    </div>
  );
};

export default HeaderInfoContainer;
