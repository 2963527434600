import { MoreOutlined } from "@ant-design/icons"
import { Button, Image, Popover } from "antd"
import { dateTimeFormater } from "res/utils"
import "./MediaAll.css"

const data = [
  {
    name: "Archivo test 1",
    date: "2024-09-27T19:30:00Z",
  },
  {
    name: "Archivo test 2",
    date: "2024-09-27T19:40:00Z",
  },
  {
    name: "Archivo test 3",
    date: "2024-09-27T19:50:00Z",
  },
  {
    name: "Archivo test 4",
    date: "2024-09-27T20:00:00Z",
  },
  {
    name: "Archivo test 5",
    date: "2024-09-27T19:30:00Z",
  },
  {
    name: "Archivo test 6",
    date: "2024-09-27T19:40:00Z",
  },
  {
    name: "Archivo test 7",
    date: "2024-09-27T19:50:00Z",
  },
  {
    name: "Archivo test 8",
    date: "2024-09-27T20:00:00Z",
  },
  {
    name: "Archivo test 9",
    date: "2024-09-27T19:30:00Z",
  },
  {
    name: "Archivo test 10",
    date: "2024-09-27T19:40:00Z",
  },
  {
    name: "Archivo test 11",
    date: "2024-09-27T19:50:00Z",
  },
  {
    name: "Archivo test 12",
    date: "2024-09-27T20:00:00Z",
  }
]

export default function MediaAll() {
  return (
    <section className="media-all-list">
      {data.map((item, index) => {
        const { name, date } = item

        return (
          <article key={index} className="media-all-item">
            <Image
              src="https://picsum.photos/200/300"
              preview={false}
              className="media-all-image"
              height="60px"
              alt={`imagen de ${name}`}
            />

            <div className="media-all-info">
              <header className="header-media-all-info">
                <h1 className="media-all-title">{name}</h1>

                <Popover
                  content={
                    <ul className="media-all-popover-list">
                      <Button type="text">Ver en el chat</Button>
                      <Button type="text">Descargar</Button>
                    </ul>
                  }
                  trigger="click"
                  rootClassName="media-all-options-btn-popover"
                >
                  <Button
                    icon={<MoreOutlined />}
                    className="media-all-options-btn"
                  >
                  </Button>
                </Popover>
              </header>

              <time dateTime={date} className="media-all-date">
                {dateTimeFormater({ date, dateStyle: "long" })}
              </time>
            </div>
          </article>
        )
      })}
    </section>
  )
}
