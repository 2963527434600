import {
  CheckSquareOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Collapse, Dropdown, Modal, Tooltip } from "antd";
import JP_SUB_PICTURE from "assets/icons/png/jp-sub-picture.png";
import ALERT_ICON_SVG from "assets/icons/svg/alert-triangle.svg";
import MEDAL_ICON_SVG from "assets/icons/svg/award.svg";
import BOOK_ICON_SVG from "assets/icons/svg/book-open.svg";
import CALENDAR_ICON_SVG from "assets/icons/svg/calendar.svg";
import CHECK_ICON_SVG from "assets/icons/svg/check.svg";
import CLOCK_ICON_SVG from "assets/icons/svg/clock.svg";
import DOLLAR_SIGN_ICON_SVG from "assets/icons/svg/dollar-sign.svg";
import EDIT_ICON_SVG from "assets/icons/svg/edit-2.svg";
import EYE_SVG from "assets/icons/svg/eye.svg";
import LOADER_ICON_SVG from "assets/icons/svg/loader.svg";
import MAIL_ICON_SVG from "assets/icons/svg/mail.svg";
import VERTICAL_DOTS from "assets/icons/svg/more-vertical.svg";
import PAUSE_ICON_SVG from "assets/icons/svg/pause.svg";
import PHONE_ICON_SVG from "assets/icons/svg/phone.svg";
import RELOAD_ICON_SVG from "assets/icons/svg/refresh-cw.svg";
import SEND_ICONS_SVG from "assets/icons/svg/send.svg";
import SHIELD_ICON_SVG from "assets/icons/svg/shield.svg";
import SLASH_ICON_SVG from "assets/icons/svg/slash.svg";
import TOOL_ICON_SVG from "assets/icons/svg/tool.svg";
import DELETE_ICON_SVG from "assets/icons/svg/trash.svg";
import TRUCK_ICON_SVG from "assets/icons/svg/truck.svg";
import USER_ICON_SVG from "assets/icons/svg/user.svg";
import X_ICON_SVG from "assets/icons/svg/x.svg";
import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { FilePicker } from "react-file-picker";
import {
  convertCodeToIconsByServices,
  convertCodeToTitleByServices,
} from "res/codes.convert.services";
import { convertTypePropertyByCodeToIcon } from "res/convert.codes";
import { camelCase } from "res/utils";
import { serviceProviderById } from "services-controller/http.cleinte.controller";
import { pasedTime } from "utils/utils.notifications";
import { getPercentPhases } from "views/services-provider/ps-new-entry/phases";
import ProgressBar from "../../atoms/progess-bar/ProgressBar";
import "./container.css";
const { Panel } = Collapse;

moment.locale("es");
const currentDate = (date) => moment(date).format("dddd, D MMMM").toUpperCase();

const diferenceTime = (mil) => {
  /*return convertMillToMin(Date.now()) - convertMillToMin(parseInt(mil))*/
  return (new Date().getTime() - new Date(mil).getTime()) / (1000 * 60);
};

const convertMinToLabel = (time) => {
  const timeParse = parseInt(time);

  if (timeParse < 1) return "Ahora";
  if (timeParse < 60)
    return `Hace ${timeParse} Minuto${timeParse === 1 ? "" : "s"}`;
  if (timeParse < 1440)
    return `Hace ${parseInt(timeParse / 60)} Hora${timeParse < 60 ? "" : "s"}`;
  if (timeParse < 10080)
    return `Hace ${parseInt(timeParse / 1440)} Día${timeParse < 1440 ? "" : "s"
      }`;
  return `Hace ${parseInt(timeParse / 10080)} Semana${timeParse < 10080 ? "" : "s"
    }`;
};

const configIcon = (key) => {
  switch (key) {
    case 0:
      return SEND_ICONS_SVG;
    case 1:
      return BOOK_ICON_SVG;
    case 2:
      return CHECK_ICON_SVG;
    case 3:
      return X_ICON_SVG;
    case 4:
      return CLOCK_ICON_SVG;
    case 5:
      return TRUCK_ICON_SVG;
    case 6:
      return CHECK_ICON_SVG;
    case 7:
      return SHIELD_ICON_SVG;
    case 8:
      return EDIT_ICON_SVG;
    case 9:
      return EDIT_ICON_SVG;
    case 10:
      return LOADER_ICON_SVG;
    case 11:
      return PAUSE_ICON_SVG;
    case 12:
      return DOLLAR_SIGN_ICON_SVG;
    case 13:
      return SLASH_ICON_SVG;
    default:
      return;
  }
};

export const configClass = (key) => {
  switch (key) {
    case 0:
      return "state-requested";
    case 1:
      return "state-to-be-approved";
    case 2:
      return "state-approved";
    case 3:
      return "state-rejected";
    case 4:
      return "state-reschedule";
    case 5:
      return "state-in-progress";
    case 6:
      return "state-done";
    case 7:
      return "state-warranty";
    case 8:
      return "state-quotation-progress";
    case 9:
      return "state-in-design";
    case 10:
      return "state-ready-execute";
    case 11:
      return "state-pause";
    case 12:
      return "state-waiting-payment";
    case 13:
      return "state-cancel";
    default:
      return "";
  }
};

export const configTitleIcon = (key) => {
  switch (key) {
    case 0:
      return "Solicitado";
    case 1:
      return "Por aprobar";
    case 2:
      return "Aprobado";
    case 3:
      return "Rechazado";
    case 4:
      return "Reajuste";
    case 5:
      return "En Obra";
    case 6:
      return "Completado";
    case 7:
      return "Garantía";
    case 8:
      return "Cotizandose";
    case 9:
      return "En diseño";
    case 10:
      return "En planeación";
    case 11:
      return "En pausa";
    case 12:
      return "Por pagar";
    case 13:
      return "Cancelado";
    default:
      return;
  }
};

const configTitle = (key) => {
  switch (key) {
    case 0:
      return "Servicio";
    case 1:
      return "Cotizacion";
    case 2:
      return "Servicio";
    case 3:
      return "Cotizacion";
    case 4:
      return "Servicio";
    case 5:
      return "Servicio";
    case 6:
      return "Servicio";
    case 7:
      return "Servicio";
    case 8:
      return "Servicio";
    case 9:
      return "Servicio";
    case 10:
      return "Servicio";
    case 11:
      return "Servicio";
    case 12:
      return "Servicio";
    default:
      return;
  }
};

const ContainerBase = ({ title, content, titleStyle, callback, id }) => {
  return (
    <div className="baseContainer" onClick={callback} id={id}>
      <div className={`title ${titleStyle}`} id={id}>
        {title}
      </div>
      <div id={id}>{content}</div>
    </div>
  );
};

const ServicesContainer = ({ object, navigatorService }) => {
  const {
    unique_key,
    state: { stage, service_creation_date },
    metadata: {
      user: {
        register_data: { name, thumbnail },
      },
      property: { property_type, nickname },
    },
    service_provider,
  } = object;

  let [phases, totalPercent] = getPercentPhases(object?.state.globalState);

  const [serviceProvider, setServiceProvider] = useState(null);

  const fetchServiceProvider = async () => {
    const serviceProvider = await serviceProviderById(service_provider);
    setServiceProvider(serviceProvider);
  };

  useEffect(() => {
    if (service_provider !== "") {
      fetchServiceProvider();
    }
  }, []);

  return (
    <div
      onClick={() => {
        navigatorService(unique_key, stage);
      }}
    >
      <div className="service-flex-container">
        <div
          className={`search-content-container ${configClass(
            stage
          )} sc-label-general`}
        >
          <div className="sc-icon-container-label">
            <img alt="" src={configIcon(stage)} className="sc-label-icon" />
          </div>
          <div className="sc-text-label">{configTitleIcon(stage)}</div>
        </div>

        <div className="service-container-hour">
          {convertMinToLabel(diferenceTime(new Date()))}
        </div>
      </div>

      {unique_key.split("~").length > 1 ? (
        <div className="title">{`Imprevisto - ${unique_key.split("~")[1]
          }`}</div>
      ) : (
        <div className="title">{`${configTitle(stage)} - ${unique_key}`}</div>
      )}

      <div className="search-content-container">
        <div className="sc-icon-container">
          <img
            alt=""
            src={CALENDAR_ICON_SVG}
            className="search-container-icon"
          />
        </div>
        {/* <div className="sc-text">{`${camelCase(
          currentDate(date_start)
        )} - ${camelCase(time_start)}`}</div> */}
      </div>

      <div className="search-content-container">
        <div className="sc-icon-container">
          <img
            alt=""
            src={
              serviceProvider?.user?.register_data?.thumbnail === ""
                ? USER_ICON_SVG
                : serviceProvider?.user?.register_data?.thumbnail
            }
            className={`${serviceProvider?.user?.register_data?.thumbnail === ""
              ? "search-container-icon"
              : "img-center-border-radius"
              }`}
          />
        </div>
        <div className="sc-text">
          {service_provider === ""
            ? "Sin Asignar"
            : serviceProvider?.user?.register_data?.name}
        </div>
      </div>

      <div className="search-content-container">
        <div className="sc-icon-container">
          <img
            alt=""
            src={convertTypePropertyByCodeToIcon(property_type)}
            className="search-container-icon"
          />
        </div>
        <div className="sc-text">{nickname}</div>
      </div>

      <div className="progress-container">
        {object?.state?.globalState !== undefined && (
          <ProgressBar phases={phases} total={totalPercent} />
        )}
      </div>
    </div>
  );
};

const NotificationsContainer = ({
  obj,
  onHandleNotiClick,
  handleMarkAsRead,
  handleRemoveById,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const name = obj?.emisor?.name || obj?.emisor?.register_data?.name;
  let avatar = obj?.emisor?.register_data?.thumbnail;
  avatar = !avatar ? JP_SUB_PICTURE : avatar;

  const nameParts = name?.split(" ");

  const displayName = nameParts?.slice(0, 2).join(" ");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleRemoveById(obj.unique_key, "notifications");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const notificationMessage = (name, service) => {
    return (
      <p>
        <strong style={{ fontWeight: "500" }}>{name}</strong> envío un nuevo
        mensaje en el servicio{" "}
        <strong style={{ fontWeight: "500" }}>{service}</strong>
      </p>
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <span style={{ display: "flex", gap: 6, fontSize: "12px" }}>
          <CheckSquareOutlined />
          Marcar como leído
        </span>
      ),
      onClick: () => {
        handleMarkAsRead(obj.unique_key);
      },
    },
    {
      key: "2",
      label: (
        <span style={{ display: "flex", gap: 6, fontSize: "12px" }}>
          {" "}
          <DeleteOutlined />
          Eliminar
        </span>
      ),
      onClick: () => {
        showModal();
      },
    },
  ];

  return (
    <div className={!obj?.read ? "spaceBetweenPsNotifications" : "noty-empty"}>
      <aside className="notification-container-box">
        <span className="avatar-container">
          <span className={!obj?.read ? "new-message-noti" : ""}></span>
          <Tooltip title={obj?.emisor?.name}>
            <Avatar src={avatar} />
          </Tooltip>
        </span>
        <span
          onClick={() => onHandleNotiClick(obj)}
          className="notification-container-info"
        >
          <p className="header-message-info">
            {notificationMessage(camelCase(displayName), obj?.serviceId)}
          </p>
          <p className="message-info">{obj?.message}</p>
        </span>
        <div className="options-noti">
          <p>{pasedTime(obj?.date)}</p>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <EllipsisOutlined className="options-selector" />
          </Dropdown>
        </div>
      </aside>
      <Modal
        title={
          <span style={{ color: "#734662" }}>
            <DeleteOutlined style={{ color: "#FD941B", marginRight: 8 }} />
            Confirmar eliminación
          </span>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Eliminar
          </Button>,
        ]}
      >
        <p>¿Estás seguro de que quieres eliminar esta notificación?</p>
      </Modal>
    </div>
  );
};

const SPServicesContainer = ({ title, date, services, callback, service }) => {
  return (
    <div className="SpSpaceBetweenContainers">
      <ContainerBase
        title={title}
        content={
          <div className="infoContainer">
            {services &&
              services.map((service, key) => (
                <div key={key} className="search-content-container">
                  <div className="sc-icon-container">
                    <img
                      alt=""
                      src={convertCodeToIconsByServices(service)}
                      className="search-container-icon"
                    />
                  </div>
                  <div className="sp-infoPersonal sp-infoGreyText">
                    {convertCodeToTitleByServices(service)}
                  </div>
                </div>
              ))}
            <div className="search-content-container">
              <div className="sc-icon-container">
                <img
                  alt=""
                  src={CALENDAR_ICON_SVG}
                  className="search-container-icon"
                />
              </div>
              <div className="sp-infoDate sp-infoGreyText">{date}</div>
            </div>
          </div>
        }
        titleStyle="spTitleStyle"
        callback={callback}
      />
    </div>
  );
};

const SearchContainer = ({
  obj,
  stage,
  uniqueKey,
  servicesSelected,
  nameCustomer,
}) => {
  return (
    <>
      <div
        className={`search-content-container ${configClass(
          stage
        )} sc-label-general`}
      >
        <div className="sc-icon-container-label">
          <img src={configIcon(stage)} className="sc-label-icon" alt="" />
        </div>
        <div className="sc-text-label">{configTitleIcon(stage)}</div>
      </div>

      {obj.unique_key.split("~").length > 1 ? (
        <div className="title">{`Imprevisto - ${obj.unique_key.split("~")[1]
          }`}</div>
      ) : (
        <div className="title">
          {configTitle(stage)} - {uniqueKey}
        </div>
      )}

      <div className="search-content-container">
        <div className="sc-icon-container">
          <img src={USER_ICON_SVG} className="search-container-icon" alt="" />
        </div>
        <div className="sp-infoPersonal sp-infoGreyText">
          {camelCase(nameCustomer)}
        </div>
      </div>
      {servicesSelected &&
        servicesSelected.map((service, key) => (
          <div key={key} className="search-content-container">
            <div className="sc-icon-container">
              <img
                src={convertCodeToIconsByServices(service)}
                className="search-container-icon"
                alt=""
              />
            </div>
            <div className="sp-infoPersonal sp-infoGreyText">
              {convertCodeToTitleByServices(service)}
            </div>
          </div>
        ))}
    </>
  );
};

const PsAssistantsContainer = ({
  name,
  photo,
  specialty,
  certificate,
  id,
  callback,
  disableClickAction = false,
}) => {
  const [selected, setSelected] = useState(false);

  const handleSelected = () => {
    if (!disableClickAction) {
      callback(id);
      setSelected(!selected);
    }
  };

  return (
    <div
      className={`SpSpaceBetweenContainers assistants-container baseContainer ${selected ? "selected-assistant" : ""
        }`}
    >
      <div className="photo-assistant-container" onClick={handleSelected}>
        <img
          src={photo === "" ? USER_ICON_SVG : photo}
          className="photo-assistant"
          alt=""
        />
      </div>

      <ContainerBase
        title={name}
        content={
          <div className="infoContainer">
            <div className="search-content-container">
              <div className="sc-icon-container">
                <img
                  src={TOOL_ICON_SVG}
                  className="search-container-icon"
                  alt=""
                />
              </div>
              <div className="sp-infoPersonal sp-infoGreyText">
                {camelCase(specialty)}
              </div>
            </div>

            {/* <div className='search-content-container'>
                        <div className='sc-icon-container'>
                            <img src={FILE_ICON_SVG} className='search-container-icon' />
                        </div>
                        <div className='sp-infoDate sp-infoGreyText'>{certificate ? 'Certificado en alturas' : 'Sin certificado en alturas'}</div>
                    </div> */}
          </div>
        }
        titleStyle="spTitleStyle"
        callback={handleSelected}
        id={id}
      />
    </div>
  );
};

const ManagementsContainer = ({ name, id, phone, email, callback }) => {
  return (
    <div
      className={`SpSpaceBetweenContainers assistants-container baseContainer`}
      onClick={callback}
    >
      <div className="photo-assistant-container">
        <img
          src={USER_ICON_SVG}
          className="photo-assistant management-icon-filter"
          alt=""
        />
      </div>

      <ContainerBase
        title={name}
        content={
          <div className="infoContainer">
            <div className="search-content-container">
              <div className="sc-icon-container">
                <img
                  src={MAIL_ICON_SVG}
                  className="search-container-icon"
                  alt=""
                />
              </div>
              <div className="sp-infoPersonal sp-infoGreyText">{email}</div>
            </div>

            <div className="search-content-container">
              <div className="sc-icon-container">
                <img
                  src={PHONE_ICON_SVG}
                  className="search-container-icon"
                  alt=""
                />
              </div>
              <div className="sp-infoDate sp-infoGreyText">{phone}</div>
            </div>
          </div>
        }
        titleStyle="spTitleStyle"
        id={id}
      />
    </div>
  );
};

const GoalsContainer = ({
  number,
  title,
  date,
  percentageCompleted,
  progressValue,
  totalValue,
  callback,
}) => {
  return (
    <div className="ps-goals-container" onClick={callback}>
      <div className="top-container">
        <div className="inner">
          <div className="goal-number-container">{number}</div>
          <div className="goal-info-container">
            <div className="gi-name-container">
              <div className="gi-name">{title}</div>
              {percentageCompleted === "100%" && (
                <img alt="" className="goal-icon" src={MEDAL_ICON_SVG} />
              )}
            </div>
            <div className="gi-end-date">Finaliza el {date}</div>
          </div>
        </div>
        <img src={VERTICAL_DOTS} className="vertical_dots" alt="" />
      </div>

      <div className="bottom-container">
        <div className="goal-bar">
          <div
            className="goal-progress-bar"
            style={{ width: percentageCompleted }}
          />
        </div>
        <div className="values-container">
          <div className="goal-value">
            $
            {
              <CurrencyFormat
                value={progressValue}
                thousandSeparator={true}
                displayType={"text"}
              />
            }
          </div>
          <div className="goal-value">
            $
            {
              <CurrencyFormat
                value={totalValue}
                thousandSeparator={true}
                displayType={"text"}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const DocumentsContainer = ({
  url,
  uploaded,
  date,
  name,
  icon,
  callbackReload,
  callbackDelete,
  callbackError,
}) => {
  const documentState = () => {
    if (uploaded) {
      return `Subido el ${date}`;
    } else {
      return "Falta por subir";
    }
  };

  return (
    <div className="ps-goals-container ps-documents-container ">
      <div className="documents-container">
        <div className="goal-number-container document-icon-container">
          <img src={icon} className="document-icon" />
        </div>
        <div className="goal-info-container documents-info-container">
          <div className="gi-name-container">
            <div className="gi-name">{name}</div>
            {!uploaded ? (
              <img
                className="goal-icon document-alert-icon"
                src={ALERT_ICON_SVG}
              />
            ) : null}
          </div>
          <div className="document-bottom-container">
            <div className="gi-end-date ">
              <span>Estado: </span>
              {documentState()}
            </div>

            <div className="documents-icons-container">
              <img
                src={EYE_SVG}
                className="documents-icons force-idon-rfp"
                onClick={() => {
                  if (url !== "") {
                    window.open(url);
                  }
                }}
              />

              <FilePicker
                maxSize={100}
                extensions={["pdf"]}
                onChange={callbackReload}
                onError={callbackError}
              >
                <img
                  src={RELOAD_ICON_SVG}
                  className="documents-icons force-idon-rfp"
                />
              </FilePicker>
              <img
                src={DELETE_ICON_SVG}
                className="documents-icons force-idon-rfp"
                onClick={callbackDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  ContainerBase,
  DocumentsContainer,
  GoalsContainer,
  ManagementsContainer,
  NotificationsContainer,
  PsAssistantsContainer, SearchContainer,
  ServicesContainer, SPServicesContainer
};

