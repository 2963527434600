import NavBar from "components/Navbar/NavBar";
import SERVICES_ICON_SVG from "../../../assets/icons/svg/servicios.svg";
import { Appbar } from "../../../components/atoms/appbar/Appbar";
import PsBigCard from "../../../components/atoms/card/psCard/PsBigCard";
import ServiceProviderMenuDesktop from "../../../components/molecules/menu/ServiceProviderMenu";
import { PS_MY_SERVICES, PS_SERVICES } from "../../../router/routes";
import "./psServices.css";
import { message, Modal } from "antd";
import { getPendingRequests, resendPendingRequests } from "../../../utils/offline.requests";
import { useEffect } from "react";

const ServiceProviderServicesMainController = () => {
  const [modal, contextHolder] = Modal.useModal();

  const fetchPendingRequests = async () => {
    const pendingRequests = await getPendingRequests();
    let trying = true;
    if (pendingRequests.length > 0) {

      modal.warning({
        title: 'Avances pendientes por enviar: ' + pendingRequests.length,
        content: 'A continuación se enviarán los avances pendientes',
        okText: 'Aceptar',
        onOk: async () => {
          message.loading('Enviando avances pendientes', 35);
        }

        // onCancel: () => {
        //   message.warning('Avances pendientes no enviados');
        // },
        // cancelText: 'Cancelar',
      });
      setTimeout(() => {
        if (trying) {
          message.warning('El avance está tardando mucho en subirse, lo intentaremos más tarde :c', 5);

        }
      }, 33000);



      await resendPendingRequests();
      trying = false;

    }
    return pendingRequests;
  }
  useEffect(() => {
    fetchPendingRequests();

  }, []);

  return (
    <div className="usertype-main-container appbar-no-background">
      <ServiceProviderMenuDesktop page={PS_SERVICES} />

      <div className="ps-services-list-container">
        <div className="new-service-appbar-container">
          <Appbar renderBackBtn={false} smallText="Tus " bigText="Servicios" />
        </div>

        <div className="ps-services-card">
          <div className="container-my-services services-type">
            <PsBigCard
              icon={SERVICES_ICON_SVG}
              text="Mis servicios"
              id={PS_MY_SERVICES}
            />
          </div>
          {contextHolder}
        </div>
      </div>

      <NavBar />
    </div>
  );
};

export default ServiceProviderServicesMainController;
