import React from "react";

const ActivitiesTitle = ({ activities, notPaid }) => {
  return activities?.map((activity, index) => (
    <div className="content-item">
      <span key={index} className={`${notPaid ? "content-item-blur" : ""}  `}>
        {activity.title}
      </span>
    </div>
  ));
};

export default React.memo(ActivitiesTitle);
