import { Empty } from "antd";
import PsNotificationsContainer from "./PsNotifications";

const PsNotificationsInformation = ({
  arr,
  title,
  onHandleNotiClick,
  getBitacleEntryInfo,
  handleRemoveById,
  handleMarkAsRead,
}) => {
  const filteredNotifications = arr.filter(
    (obj) => obj?.title !== "Nueva Entrada"
  );
  return (
    <>
      <div className="informationConteiner-title">{title}</div>
      <div className="informationContainer">
        {arr.length === 0 ? (
          <Empty
            description={"No hay notificaciones"}
            style={{ marginTop: "60px" }}
          />
        ) : filteredNotifications.length > 0 ? (
          filteredNotifications.map((obj, key) => (
            <PsNotificationsContainer
              key={key}
              obj={obj}
              getBitacleEntryInfo={getBitacleEntryInfo}
              onHandleNotiClick={onHandleNotiClick}
              handleRemoveById={handleRemoveById}
              handleMarkAsRead={handleMarkAsRead}
            />
          ))
        ) : (
          <Empty
            description={"No hay notificaciones"}
            style={{ marginTop: "60px" }}
          />
        )}
      </div>
    </>
  );
};

export default PsNotificationsInformation;
