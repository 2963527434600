import { SERVICES } from "../router/routes";
import { get, offlinePut, post, put, remove } from "../services/handler.request";

const USER_BY_ID = "userById";
const USER_PROPERTIES = "userPropertiesById";
const USER_MANAGEMENTS = "userManagementsById";

const CREATE_USER_BY_ID = "createUserById";
const CREATE_PROPERTY_BY_ID = "createPropertyById";
const CREATE_MANAGEMENT_BY_ID = "createManagementById";

const CREATE_SERVICE_PROVIDER_BY_ID = "service-provider";

const CREATE_SERVICE = "services";

const PATH_PROPERTIES = "properties";

const HELPER = "helper";

const MAILCHIMP = "syncMailchimp";
const INVITATION_FOR_MANAGEMENT = "/invitationForManager";

const ACTION_ACCEPT = "accept";
const ACTIONS_ITEMS = "items";
const ACTION_METADATA = "metadata";
const ACTION_STAGE = "stage";

const BITACLE = "bitacle";

const MESSAGE = "messages";

const SERVICE_PROVIDER = "service-provider";

const USERS = "users";

const MANAGEMENT = "management";

const PROPERTY = "property";

const HELPERS = "helpers";

//___---GET---___

// OLD

const userById = async (id) => await get(`${USER_BY_ID}?id=${id}`);

const userPropertiesById = async (id) =>
  await get(`${USER_PROPERTIES}?id=${id}`);

const userManagementsById = async (id) =>
  await get(`${USER_MANAGEMENTS}?id=${id}`);

// NEW

const serviceProviderById = async (id) =>
  await get(`${CREATE_SERVICE_PROVIDER_BY_ID}/${id}`);

const userPropertyById = async (id) => await get(`${PATH_PROPERTIES}/${id}`);

const allServicesAcceptByServiceProvider = async () =>
  await get(CREATE_SERVICE);

const allServices = async () => await get(CREATE_SERVICE + "/all");

const allItemsByService = async (service) =>
  await get(`${CREATE_SERVICE}/${service}/${ACTIONS_ITEMS}`);

const allMetadataByFormQuote = async () =>
  await get(`${CREATE_SERVICE}/${ACTION_METADATA}`);

const getServiceById = async (id) => await get(`${CREATE_SERVICE}/${id}`);

const getAllHelpers = async (id) =>
  await get(`${CREATE_SERVICE_PROVIDER_BY_ID}/${id}/${HELPER}`);

const getAllEntriesByService = async (id) =>
  await get(`${CREATE_SERVICE}/${id}/${`BITACLE`}`);

const getServiceDetails = async (id) =>
  await get(`${CREATE_SERVICE}/${id}/detail`);

const getAnalyticsClient = async (id) => await get(`users/${id}/analytics`);

const getConfigCosts = async () =>
  await get(`${SERVICE_PROVIDER}/metadata/costs`);

const getManagementById = async (uid) =>
  await get(`${USERS}/management/${uid}`);

const getGoals = async (uid) => await get(`${SERVICE_PROVIDER}/${uid}/goals`);

const getServiceHelpers = async (id) =>
  await get(`${SERVICES}/${id}/${HELPERS}`);
const getWarrantiesByUser = async (id) => await get(`${USERS}/${id}/warranty`);

const getAnalyticsServiceProvider = async (id) =>
  await get(`${SERVICE_PROVIDER}/${id}/analytics`);

const getRecordByProperty = async (id) =>
  await get(`${USERS}/${PROPERTY}/${id}/history`);

//___---POST---___
const createUserById = async (id, object) =>
  await post(`${CREATE_USER_BY_ID}?id=${id}`, {
    user: object,
  });

const createPropertyById = async (id, object) =>
  await post(`${CREATE_PROPERTY_BY_ID}?id=${id}`, {
    property: object,
  });

const createUnexpectedActivity = async (serviceId, object) => {
  try {
    const res = await post(`${CREATE_SERVICE}/${serviceId}/phases/unexpected`, {
    unexpected: object,
  });
    return res
  } catch (error) {
    console.log(error);
    return error
  }
}

const createManagementById = async (id, object) =>
  await post(`${CREATE_MANAGEMENT_BY_ID}?id=${id}`, {
    management: object,
  });

const createNewService = async (object) =>
  await post(CREATE_SERVICE, {
    service: object,
  });

const createItemInQuote = async (id, object) =>
  await post(`${CREATE_SERVICE}/${id}/${ACTIONS_ITEMS}`, {
    item: object,
  });

const createNewHelper = async (id, email, name) =>
  await post(`${CREATE_SERVICE_PROVIDER_BY_ID}/${id}/${HELPER}`, {
    email: email,
    name: name,
  });

const createNewEntry = async (id, userType, object, userInfo) => {
  try {
    const res = await post(`${CREATE_SERVICE}/${id}/${BITACLE}/${userType}`, {
      bitacle: object,
      userInfo: userInfo,
    });
    return res;

  } catch (error) {
    console.log(error);
    return error
  }
}

const createNewMessage = async (id, entryId, userType, object, user) => {
  try {
    const response = await post(
      `${CREATE_SERVICE}/${id}/${BITACLE}/${entryId}/${MESSAGE}/${userType}`,
      { message: object, user: user }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const deleteBitacleEntry = async (idService, idEntry) =>
  await remove(`${CREATE_SERVICE}/${idService}/bitacle/${idEntry}/delete`);

const updateMessage = async (
  serviceId,
  entryId,
  messageId,
  userType,
  object
) => {
  await put(
    `${CREATE_SERVICE}/${serviceId}/${BITACLE}/${entryId}/messages/${messageId}/${userType}`,
    { message: object }
  );
};

const createUnexpected = async (serviceId, object, userInfo) =>
  await post(`${CREATE_SERVICE}/${serviceId}/unexpected`, {
    service: object,
    userInfo: userInfo,
  });
const validateCode = async (email, query) => {
  const uid = localStorage.getItem("uid");
  return await post(
    `associateManagement?id=${uid}&query=${query}&email=${email}`
  );
};

const sendPdfResource = async (serviceId, fileName, pdfBuffer) =>
  await post(`${CREATE_SERVICE}/${serviceId}/send_pdf`, {
    pdfFile: {
      fileName: fileName,
      pdfBlob: pdfBuffer,
    },
  });

const createRejectionFeedback = async (id, object) =>
  await post(`${CREATE_SERVICE}/${id}/feedback`, { feedback: object });

const createNewGoal = async (uid, object) =>
  await post(`${SERVICE_PROVIDER}/${uid}/goals`, { goal: object });

const updateServiceHelpers = async (id, object) =>
  await post(`${SERVICES}/${id}/${HELPERS}`, { helpers: object });

const removeServiceHelpers = async (id, object) =>
  await post(`${SERVICES}/${id}/${HELPERS}/delete`, { helpers: object });

//___---PUT---___
const createServiceProviderById = async (id, user, coverage) =>
  await put(`${CREATE_SERVICE_PROVIDER_BY_ID}/${id}`, {
    user,
    coverage,
  });

const updateNotification = async (notificationId, object) => {
  const parentId = localStorage.getItem("uid");
  await put(`services/notifications/${parentId}/${notificationId}`, {
    notification: object,
  });
};

const updateStageByServiceId = async (id, code) =>
  await put(`${CREATE_SERVICE}/${id}/${ACTION_STAGE}`, {
    stage: code,
  });

const updateEntry = async (id, entryId, userType, entry) =>
  await put(`${CREATE_SERVICE}/${id}/${BITACLE}/${entryId}/${userType}`, {
    entry: entry,
  });

const readEntry = async (id, entryId, userType) =>
  await put(
    `${CREATE_SERVICE}/${id}/${BITACLE}/${entryId}/readEntry/${userType}`
  );
const readMessages = async (serviceId, entryId, userType, object) =>
  await put(
    `${CREATE_SERVICE}/${serviceId}/${BITACLE}/${entryId}/messages/${userType}`,
    { user: object }
  );

const acceptService = async (service) =>
  await put(`${CREATE_SERVICE}/${service}/${ACTION_ACCEPT}`);

const updateItem = async (id, idItem, object) =>
  await put(`${CREATE_SERVICE}/${id}/items/${idItem}`, { item: object });

const updateServiceSchedule = async (id, object) =>
  await put(`${CREATE_SERVICE}/${id}`, { service: object });

const editManagement = async (id, object) =>
  await put(`${USERS}/${MANAGEMENT}/${id}`, { management: object });

const editProperty = async (id, object) =>
  await put(`${USERS}/${PROPERTY}/${id}`, { propery: object });

const editGoal = async (uid, object, idGoal) =>
  await put(`${SERVICE_PROVIDER}/${uid}/goals/${idGoal}`, { goal: object });

const editDocument = async (uid, object) =>
  await put(`${SERVICE_PROVIDER}/${uid}/documents`, { doc: object });

const updateServiceProviderProfile = async (id, object) =>
  await put(`${SERVICE_PROVIDER}/${id}/profile`, { provider: object });

const updateClientProfile = async (id, object) =>
  await put(`${USERS}/${id}/profile`, { user: object });

//__--DELETE--__
const removeItem = async (id, idItem) =>
  await remove(`${CREATE_SERVICE}/${id}/items/${idItem}`);

//___---MAILCHIMP---___
const syncMailchimpInvitationForManager = async (user, property, management) =>
  await post(`${MAILCHIMP}${INVITATION_FOR_MANAGEMENT}`, {
    user,
    property,
    management,
  });

const deleteTask = async (serviceId, itemId, taskId) =>
  await remove(`${CREATE_SERVICE}/${serviceId}/items/${itemId}/task/${taskId}`);

const deleteManagement = async (id) =>
  await remove(`${USERS}/${MANAGEMENT}/${id}`);

const deleteProperty = async (id) => await remove(`${USERS}/${PROPERTY}/${id}`);

const deleteGoal = async (uid, idGoal) =>
  await remove(`${SERVICE_PROVIDER}/${uid}/goals/${idGoal}`);

const setOperationPercent = async (serviceId, phaseId, servicePhases) =>
  await post(`${CREATE_SERVICE}/${serviceId}/phases/${phaseId}/percent`, {
    servicePhases: servicePhases,
  });

const updateActivity = async (serviceId, phaseId, object) => {
  console.log(serviceId, phaseId, object);
  await put(`${CREATE_SERVICE}/${serviceId}/phases/${phaseId}/activities`, {
    activity: object,
  });
};
const updateSpaceActivity = async (
  serviceId,
  phaseId,
  activityGroup,
  activity,
  space,
  value
) =>
  await put(
    `${CREATE_SERVICE}/${serviceId}/phases/${phaseId}/activities/spaces`,
    {
      activityGroup: activityGroup,
      activitySelected: activity,
      spaceSelected: space,
      valueComplete: value,
    }
  );
const completePhase = async (serviceId, phaseId) =>
  await put(`${CREATE_SERVICE}/${serviceId}/phases/${phaseId}/complete`);

const pauseService = async (serviceId) =>
  await put(`${CREATE_SERVICE}/${serviceId}/pause`);
const despuaseService = async (serviceId) =>
  await put(`${CREATE_SERVICE}/${serviceId}/despause`);

const addPhases = async (serviceId, object) =>
  await post(`${CREATE_SERVICE}/${serviceId}/phases`, { phases: object });
const getPhases = async (serviceId) =>
  await get(`${CREATE_SERVICE}/${serviceId}/phases`);
const updatePhase = async (serviceId, phaseId, object) =>
  await put(`${CREATE_SERVICE}/${serviceId}/phases/${phaseId}`, {
    phase: object,
  });

const loginAuthJWT = async (token) => {
  try {
    const response = await post(`auth/token`, { token: token });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const removeNotifications = async (id, userId) => {
  return await remove(`services/notification/${id}/${userId}`);
};

const getServiceOperationById = async (id) => {
  return await get(`operation-work/service-operation/${id}`);
};

const getBudgetById = async (id) => {
  return await get(`budgets/${id}`);
};

const createAdvance = async (object) => {
  return await offlinePut(`operation-work/activities/advance`, object);
};

const passOrderToOnSite = async (id, order_id, emisor) => {
  return await put(`materials-by-service/${id}/update-order/${order_id}`, { emisor });
}

export const getAllMaterials = async () => {
  try {
    const res = await get(`logistic/materials`);
    return res;
  } catch (error) {
    console.log(error);
  }
}

const createNewServiceRequest = async (id, requestObj, emisor) => {
  return await post(`materials-by-service/${id}/new-request/`, { requestObj: requestObj, emisor });
}


export {
  acceptService,
  addPhases,
  allItemsByService,
  allMetadataByFormQuote,
  createNewServiceRequest,
  allServices,
  allServicesAcceptByServiceProvider,
  completePhase, createAdvance, createItemInQuote,
  createManagementById,
  createNewEntry,
  createNewGoal,
  createNewHelper,
  createNewMessage,
  createNewService,
  createPropertyById,
  createRejectionFeedback,
  createServiceProviderById,
  createUnexpected,
  createUnexpectedActivity,
  createUserById, deleteBitacleEntry, deleteGoal,
  deleteManagement,
  deleteProperty,
  deleteTask,
  despuaseService,
  editDocument,
  editGoal,
  editManagement,
  editProperty,
  getAllEntriesByService,
  getAllHelpers,
  getAnalyticsClient,
  getAnalyticsServiceProvider, getBudgetById, getConfigCosts,
  getGoals,
  getManagementById,
  getPhases,
  getRecordByProperty,
  getServiceById,
  getServiceDetails,
  getServiceHelpers, getServiceOperationById, getWarrantiesByUser,
  loginAuthJWT, passOrderToOnSite, pauseService,
  readEntry,
  readMessages,
  removeItem,
  removeNotifications,
  removeServiceHelpers,
  sendPdfResource,
  serviceProviderById,
  setOperationPercent,
  syncMailchimpInvitationForManager,
  updateActivity,
  updateClientProfile,
  updateEntry,
  updateItem,
  updateMessage,
  updateNotification,
  updatePhase,
  updateServiceHelpers,
  updateServiceProviderProfile,
  updateServiceSchedule,
  updateSpaceActivity,
  updateStageByServiceId,
  userById,
  userManagementsById,
  userPropertiesById,
  userPropertyById,
  validateCode
};

