import useReduxState, { useDispatcherFunction } from "custom-hooks/useReduxState";
import { useEffect } from "react";
import { addMessage, setActivitiesSectionActive, setInitialChat, setMediaSectionActive } from "reducers/bitacle.reducer";
import ActivitiesSection from "./components/activtiesSection/ActivitiesSection";
import Chat from "./components/chat/Chat";
import Header from "./components/header/Header";
import MediaSection from "./components/mediaSection/MediaSection";
import "./NewBitacle.css";
import { updateMessage } from "services-controller/http.cleinte.controller";
import { useSelector, shallowEqual } from "react-redux";
import { getChangedMessages, getInitialMessages, getNewMessages } from "services-controller/database";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function NewBitacle() {
  const [{ isActivitiesSectionActive, isMediaSectionActive, }, dispatch] = useReduxState({ setActivitiesSectionActive, setMediaSectionActive }, "bitacle")
  const chatPhaseSelected = useSelector((state) => state.bitacle.chatPhaseSelected, shallowEqual)
  console.log(chatPhaseSelected)
  const updateMessages = useDispatcherFunction(setInitialChat)
  const changedMessage = useDispatcherFunction(updateMessage)
  const newMessage = useDispatcherFunction(addMessage)
  const { id } = useParams()



  useEffect(() => {
    if (isActivitiesSectionActive) {
      dispatch.setActivitiesSectionActive(false)
    }

    if (isMediaSectionActive) {
      dispatch.setMediaSectionActive(false)
    }
  }, []) //eslint-disable-line


  const user = useSelector((state) => state.auth.user)
  function mappingMessages(messages) {
    console.log(messages)
    return messages.map((item) => ({
      ...item,
      isOtherUser: item.emisor?.id !== user.id,
    }));
  }

  const handleNewMessages = async (messages) => {
    updateMessages(mappingMessages(messages));
  };


  useEffect(() => {
    const listenerMessages = getChangedMessages(
      id,
      chatPhaseSelected.key,
      async (snapshot) => {
        changedMessage(snapshot.val());
      }
    );

    let newMessageListener = () => { };
    getInitialMessages(id, chatPhaseSelected.key).then((messages) => {
      handleNewMessages(messages);
      newMessageListener = getNewMessages(
        id,
        chatPhaseSelected.key,
        (snapshot) => {
          newMessage(snapshot.val());
        },
        messages.length
      );
    });

    return () => {
      listenerMessages();
      newMessageListener();
      updateMessages([]);
    };

  }, [chatPhaseSelected.key, id]);


  return (
    <main className="new-bitacle-view">
      <Header />
      <Chat />
      <MediaSection />
      <ActivitiesSection />
    </main>
  )
}
