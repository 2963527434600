export default function MediaFiles() {
  return (
    <div>
      MediaFiles

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi fuga modi dolorem omnis eius tempora, repellat id in sapiente repudiandae nobis culpa, hic qui amet veritatis expedita voluptates impedit non?

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione sed delectus quasi in vel, nihil, quo est ducimus aut explicabo saepe accusamus libero iusto? Repellendus neque ipsum maiores expedita tenetur?

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti sit soluta sed esse dolorem molestias placeat quod tenetur maxime nisi laudantium ut totam voluptatem, beatae sequi optio labore quam adipisci.

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, animi vero. Facere aspernatur itaque provident neque obcaecati, iste ipsum vel eaque perferendis odit fugiat ullam, iure ipsa impedit dolorem aliquam.

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque est ab tenetur tempora nesciunt natus facilis consequatur adipisci nobis, consequuntur dolorem numquam eligendi eveniet ullam accusamus voluptate facere nam rem.

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi fuga modi dolorem omnis eius tempora, repellat id in sapiente repudiandae nobis culpa, hic qui amet veritatis expedita voluptates impedit non?

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione sed delectus quasi in vel, nihil, quo est ducimus aut explicabo saepe accusamus libero iusto? Repellendus neque ipsum maiores expedita tenetur?

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti sit soluta sed esse dolorem molestias placeat quod tenetur maxime nisi laudantium ut totam voluptatem, beatae sequi optio labore quam adipisci.

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, animi vero. Facere aspernatur itaque provident neque obcaecati, iste ipsum vel eaque perferendis odit fugiat ullam, iure ipsa impedit dolorem aliquam.

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque est ab tenetur tempora nesciunt natus facilis consequatur adipisci nobis, consequuntur dolorem numquam eligendi eveniet ullam accusamus voluptate facere nam rem.
    </div>
  )
}
