import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import ALERT_ICON_SVG from "../../../assets/icons/svg/info.svg";
import { Appbar } from "../../../components/atoms/appbar/Appbar";
import { BtnPayPayU } from "../../../components/atoms/button/Button";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import ProgressBar from "../../../components/atoms/progess-bar/ProgressBar";
import PsButtonNavbar from "../../../components/molecules/buttonNavbar/PsButtonNavbar";
import ServiceProviderMenuDesktop from "../../../components/molecules/menu/ServiceProviderMenu";
import UserMenuDesktop from "../../../components/molecules/menu/UserMenu";
import useReduxState from "../../../custom-hooks/useReduxState.js";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons";
import { PopupRejectEvent } from "../../../popups/psPopups/PopupRejectEvent.jsx";
import PopupServiceDetails from "../../../popups/psPopups/PopupServiceDetails";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import { setGlobalState } from "../../../reducers/globalState.reducer.js";
import { updateStateEntry } from "../../../reducers/state.entry.reducer";
import { PRINCIPAL_PATH, PS_SERVICES, SERVICES } from "../../../router/routes";
import { existUser } from "../../../services-controller/auth";
import {
  getAllEntriesByService,
  getManagementById,
  getServiceById,
  readEntry,
  readMessages,
} from "../../../services-controller/http.cleinte.controller";
import { currentUser } from "../../../services-firebase/auth";
import PsAssistantsListController from "../../services-provider/ps-assistants/PsAssistantsListController.jsx";
import { getPercentPhases } from "../ps-new-entry/phases";
import PsSeguimientoContainer from "./PsSeguimientoContainer";
import "./psSeguimiento.css";
const PsSeguimiento = ({ user = null, history, pay, callbackPayment }) => {
  moment.locale("es");
  let { idService, id } = useParams();
  const TypeUser = user === null ? "sp" : user;
  const dispatch = useDispatch();
  const [entriesList, setEntriesList] = useState([]);
  const [service, setService] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [showServiceDetails, setShowServiceDetails] = useState(false);
  const [showSelectedHelpers, setShowSelectedHelpers] = useState(false);
  const [showCompletedConfirmation, setShowCompletedConfirmation] =
    useState(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);
  const [showFinishedDay, setShowFinishedDay] = useState(false);
  const [management, setManagement] = useState(null);
  const [removeSelectedHelpers, setRemoveSelectedHelpers] = useState(false);
  const [uid, setUser] = useState("");
  const [showRejectEvent, setShowRejectEvent] = useState(false);
  const [procedureUrl, setProcedureUrl] = useState("");
  const [activeId, setActiveTab] = useState(0);
  const [globalState, dispatchGlobalState] = useReduxState(
    { setGlobalState },
    "globalState"
  );

  const percentAvance = service?.state?.globalState?.progress.progress * 100;

  const useId = idService !== undefined ? idService : id;

  const fetchEntriesById = async () => {
    setIsLoader(true);

    const uid = currentUser().uid;
    setUser(uid);

    const [entries, service] = await Promise.all([
      getAllEntriesByService(useId),
      getServiceById(useId),
    ]);

    if (user === "client") {
      entries.bitacle = entries.bitacle.filter((bitacleEntry) => {
        bitacleEntry.entrys = bitacleEntry.entrys.filter(
          (entry) => entry.is_visible
        );
        return bitacleEntry.entrys.length > 0;
      });

      setEntriesList(entries.bitacle.reverse());
    } else {
      setEntriesList(entries.bitacle.reverse());
    }

    setService(service);
    dispatchGlobalState.setGlobalState(service.state.globalState);
    setProcedureUrl(entries.url);
    if (entries.bitacle.length > 0 && service.state.stage === 10) {
      //updateStageByServiceId(useId, 5);
    }

    if (service.metadata.property.management !== "") {
      const management = await getManagementById(
        service.metadata.property.management
      );
      setManagement(management);
    }

    setIsLoader(false);
  };

  useEffect(() => {
    if (!existUser()) {
      history.push(PRINCIPAL_PATH);
    } else {
      fetchEntriesById();
    }

    return () => { };
  }, [useId]);

  const callbackNewEntry = () => {
    // if (service.version !== 2) {
    history.push(`/services/entry/${useId}`);
    // } else {
    //   history.push(`/new-advance-detail/${useId}`);
    // }
  };

  const tabList = [
    { label: "Bitacora", id: 1 },
    { label: "Entradas", id: 0 },
  ];

  /*   const callbackAddAssinstant = () => {
    //setShowSelectedHelpers(true);
  };

  const callbackViewDetails = () => {
    //setShowServiceDetails(true);
  };

  const callbackRemoveAssistant = () => {
    //setRemoveSelectedHelpers(true);
  }; */

  /* const callbackDownloadPdf = () => {
    //window.open(procedureUrl);
  }; */

  const callbackNewUnexpected = () => {
    // if (!service.version) {
    history.push(`/services/newUnexpected/${useId}`);
    // } else {
    //   callbackNewEntry();
    // }
  };

  const moreValues = [
    /*     ["Ver detalles", callbackViewDetails],
    ["Descargar Planos", callbackDownloadPdf], */
    ["Nueva entrada", callbackNewEntry],
    // ["Nueva Adicional", callbackNewAdditional],
    ["Nueva Imprevisto", callbackNewUnexpected],
    /*    ["Agregar ayudante", callbackAddAssinstant],
    ["Remover ayudante", callbackRemoveAssistant], */
  ];

  const markEntryAsRead = async (entry, isClient, service) => {
    await readEntry(useId, entry.unique_key, "sp", entry);
    if (
      (entry.isNewClientEntry && isClient === "client") ||
      service.isNewClientEntry
    ) {
      await readEntry(useId, entry.unique_key, "client", entry);
    }
  };

  const markMessageAsRead = async (entry, roleType) => {
    switch (roleType) {
      case "client":
        if (entry?.statusMessages?.newClientMessage) {
          await readMessages(useId, entry?.unique_key, "client", user);
        }
        break;
      case "sp":
        if (entry?.statusMessages?.newSpMessage) {
          await readMessages(useId, entry?.unique_key, "sp", user);
        }
        break;
      default:
        return null;
    }
  };

  const callbackEntryInfo = async (entry) => {
    let targetEntry = entriesList
      .flatMap((entrys) => entrys.entrys)
      .find((newEntry) => newEntry.unique_key === entry.unique_key);

    await markEntryAsRead(
      targetEntry,
      user === "client" ? "client" : false,
      service
    );
    await markMessageAsRead(targetEntry, user === "client" ? "client" : false);
    dispatch(
      updateStateEntry({
        object: targetEntry,
        id: useId,
        userType: user === "client" ? "client" : "sp",
        service: service,
      })
    );

    history.push({
      pathname: `/services/info/entry/${useId}/${targetEntry.unique_key}`,
    });
  };

  const callbackCloseServiceDetails = () => {
    setShowServiceDetails(false);
  };

  const callbackCloseAssistantsPopup = () => {
    setShowSelectedHelpers(false);
    setRemoveSelectedHelpers(false);
  };

  const confirmCompleted = async () => {
    setShowCompletedConfirmation(false);
    //updateStageByServiceId(useId, 12);
    setShowCompletedPopup(true);
  };

  const rejectCompleted = () => {
    setShowCompletedConfirmation(false);
  };

  const callbackCloseCompleted = () => {
    setShowCompletedPopup(false);
    history.push(PRINCIPAL_PATH);
  };

  const callbackCloseFinishedDay = () => {
    setShowFinishedDay(false);
    history.push(PRINCIPAL_PATH);
  };

  const shortDescription = (descriptionString) => {
    let arr = descriptionString.split("");
    if (arr.length > 200) {
      arr.splice(200, arr.length - 200, "...");
      return arr.join("");
    } else {
      return descriptionString;
    }
  };

  const callbackCloseRejectEvent = () => {
    setShowRejectEvent(false);
  };

  let [phases, totalPercent] = getPercentPhases(service?.state?.globalState);
  return (
    <>
      <div className="new-service-container">
        {user === "client" ? (
          <UserMenuDesktop page={SERVICES} showWhatsappButton={true} />
        ) : (
          <ServiceProviderMenuDesktop
            page={PS_SERVICES}
            showSeguimientoOptionsButton={true}
            moreValues={moreValues}
          />
        )}

        <div className="ps-services-list-container desktop-container">
          <div className="new-service-appbar-container">
            {service?.state?.globalState === undefined ? (
              <Appbar
                smallText="Seguimiento"
                bigText={`Caso ${useId}`}
                isTogle={false}
              />
            ) : (
              <Appbar
                smallText="Seguimiento"
                bigText={`Caso ${useId}`}
                isTogle={true}
                activeTab={activeId}
                setActiveTab={setActiveTab}
                tabList={tabList}
              />
            )}
          </div>

          <div className="my-services-container seguimiento-container">
            {service?.state?.globalState !== undefined ? (
              <div style={{ padding: "20px" }}>
                <div className="seguimiento-header">
                  <div className="seguimiento-header-title">
                    Genial! vamos al <span>{Math.round(percentAvance)}%</span>{" "}
                    de cumplir tu sueño
                  </div>
                  <ProgressBar phases={phases} total={totalPercent} />
                </div>
                <PsSeguimientoContainer
                  props={{
                    user: TypeUser,
                    callbackEntryInfo: callbackEntryInfo,
                    entriesList: entriesList,
                    serviceId: useId,
                    stage: service?.state?.stage,
                  }}
                  activeId={activeId}
                />
              </div>
            ) : (
              <PsSeguimientoContainer
                props={{
                  user: TypeUser,
                  callbackEntryInfo: callbackEntryInfo,
                  entriesList: entriesList,
                  serviceId: useId,
                }}
                activeId={0}
              />
            )}

            {parseInt(service?.state?.stage) === 12 && user === "client" && (
              <BtnPayPayU
                callbackPayment={callbackPayment}
                serviceId={useId}
                email={
                  service?.metadata?.user?.register_data?.contact
                    .email_associated
                }
                description={shortDescription(
                  `Justo Pago: ${service?.request?.general_description}`
                )}
                pay={pay}
                completed={true}
              />
            )}

            <div className="menu-navbar-container">
              {user !== "client" && (
                <PsButtonNavbar
                  page={PS_SERVICES}
                  showSeguimientoOptionsButton={service?.state?.stage !== 6}
                  moreValues={moreValues}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <LoadingPopup state={isLoader} />

      {showServiceDetails && (
        <StateButtonModalHook
          component={PopupServiceDetails}
          hook={[showServiceDetails, setShowServiceDetails]}
          object={{
            service: service,
            callbackClose: callbackCloseServiceDetails,
          }}
        />
      )}

      {showSelectedHelpers && (
        <StateButtonModalHook
          component={PsAssistantsListController}
          hook={[showSelectedHelpers, setShowSelectedHelpers]}
          object={{
            service: service,
            callbackCloseAssistantsPopup: callbackCloseAssistantsPopup,
            id: useId,
          }}
        />
      )}

      {showCompletedConfirmation && (
        <StateButtonModalHook
          component={PopupTwoOptionWithIcon}
          hook={[showCompletedConfirmation, setShowCompletedConfirmation]}
          object={{
            callbackNegative: rejectCompleted,
            callbackPositive: confirmCompleted,
            textPositive: "Si",
            textNegative: "No",
            popupText:
              "¿Desea terminar el servicio? Esta acción solo se debe realizar una vez el servicio este finalizado en su totalidad.",
            popupIcon: ALERT_ICON_SVG,
          }}
        />
      )}

      {showCompletedPopup && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showCompletedPopup, setShowCompletedPopup]}
          object={{
            message: "¡Servicio completado exitosamente!",
            callback: callbackCloseCompleted,
            btnText: "Ok ",
          }}
        />
      )}

      {showFinishedDay && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showFinishedDay, setShowFinishedDay]}
          object={{
            message:
              "Has finalizado la obra por el día de hoy. La fecha de ejecución del servicio ha sido actualizada.",
            callback: callbackCloseFinishedDay,
            btnText: "Ok ",
          }}
        />
      )}

      {removeSelectedHelpers && (
        <StateButtonModalHook
          component={PsAssistantsListController}
          hook={[removeSelectedHelpers, setRemoveSelectedHelpers]}
          object={{
            service: service,
            callbackCloseAssistantsPopup: callbackCloseAssistantsPopup,
            id: useId,
            removeHelpers: true,
          }}
        />
      )}

      {showRejectEvent && (
        <StateButtonModalHook
          component={PopupRejectEvent}
          hook={[showRejectEvent, setShowRejectEvent]}
          object={{
            message:
              "Lo sentimos, solo el prestador de servicio asignado al caso puede finalizar el día.",
            btnText: "OK",
            callback: callbackCloseRejectEvent,
            icon: FROWN_ICON_SVG,
          }}
        />
      )}
    </>
  );
};

export default withRouter(PsSeguimiento);
