import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import { WrapSource } from "components/atoms/mediaSource/MediaSource";
import useFile from "custom-hooks/useFile";
import { useState } from "react";
import "./InputFile.css";

export default function InputFile({
  images = [],
  labelTitle = "",
  deleteMediaSource = () => {},
  onAddFiles = () => {},
  limit,
  filesAccept,
  disabled = false,
}) {
  const file = useFile(images, onAddFiles, () => {}, limit, filesAccept);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  return (
    <div className="images-input-container input-form">
      <label>{labelTitle}</label>
      <div className="images-container">
        <Row style={{ width: "100%" }}>
          {!disabled && (
            <Col>
              <Tooltip title="Agregar Imagen" placement="right">
                <Button
                  type="dashed"
                  shape=""
                  size="large"
                  style={{
                    minHeight: "100px",
                    minWidth: "100px",
                    width: "120px",
                    height: "120px",
                  }}
                  icon={<PlusOutlined />}
                  onClick={file.openFileSelector}
                />
              </Tooltip>
            </Col>
          )}
          {images?.map((image, index) => (
            <Col
              onMouseEnter={() => setShowDeleteButton(true)}
              onMouseLeave={() => setShowDeleteButton(false)}
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <WrapSource
                index={index}
                type={image.type}
                file={image.name || image.url}
                url={image.url}
                className="img-wrapper"
              />

              <Popconfirm
                title="¿Estás seguro de eliminar esta imagen?"
                onConfirm={() => deleteMediaSource(index)}
                okText="Si"
                cancelText="No"
              >
                {showDeleteButton && (
                  <Button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    type="text"
                    icon={<DeleteOutlined />}
                  />
                )}
              </Popconfirm>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
